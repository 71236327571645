import { gsap } from "gsap";
import lottie from 'lottie-web';

let carousel;
let windowWidth;

window.addEventListener(
  'load',
  () => {
    windowWidth = window.innerWidth
    let radius = windowWidth;
    if(windowWidth >= 768) radius = windowWidth / 2;
    gsap
      .timeline({
        defaults: {
          ease: "power2.out",
        },
      })
      .to('.header-logo', { opacity: 1, marginTop: 0, duration: 1 }, 0)
      .to('.header__right', { opacity: 1, marginTop: 0, duration: 1 }, 1)
      .to('.cv-area__inner', { opacity: 1, x: 0, duration: 1 }, 1)
      .add(function() {
        var showcase = $(".kv-slider__blc");

        carousel = showcase.Cloud9Carousel({
          xRadius: radius,
          transforms: false,
          yOrigin: 0,
          yRadius:  50,
          itemClass: "kv-slider__item",
          autoPlay: true,
          autoPlayDelay: 6000,
          bringToFront: true,
          farScale: 0.84,
          frontItemClass: "kv-slider__center",
          onLoaded: function() {
            showcase.css('visibility', 'visible')
            showcase.fadeIn(1500)
            setTimeout(function() {
              exitLottieAnimation("icon01");
            }, 4500)
          },
          onAnimationFinished: function() {
            let currentElement = document.querySelector(".kv-slider__center");
            let num = currentElement.dataset.num;
            exitLottieAnimation("icon" + num);
            $('.kv-slider-pagination__item').removeClass("active");
            $('[data-pagination="' + num + '"]').addClass("active");
          }
        })
      }, 1)
      .to('.top-kv__inner', { opacity: 1, duration: 2 }, 2)
      .to('.kv-slider-pagination', { opacity: 1, duration: 2 }, 2)
  },
  false
);

window.addEventListener( 'resize',
  () => {
    windowWidth = window.innerWidth;
    if(windowWidth >= 768) {
      carousel.data().carousel.xRadius= windowWidth / 2;
    }
    else {
      carousel.data().carousel.xRadius= windowWidth;
    }
  },
false);


let pagination = document.querySelectorAll(".kv-slider-pagination__item");
for(let i = 0; i < pagination.length; i++) {
  pagination[i].addEventListener(
    'click',
    (e) => {
      let current = e.currentTarget;
      let number = current.dataset.pagination;
      $('[data-num="' + number + '"]').trigger("click");
    }
  )
}


/**
 * lottie
 */
// icon - デフォルト
var defaultOption = {
  renderer: "svg",
  loop: false,
  autoplay: false,
}


// slide01

var addOption01 = {
  container: document.querySelector(".kv-slider__img--01"),
  path: "/wp-content/themes/hoan-supply/img/top/img_top_kv_01.json"
}
var icon01 = lottie.loadAnimation(
  Object.assign({}, defaultOption, addOption01)
);

// slide02
var addOption02 = {
  container: document.querySelector(".kv-slider__img--02"),
  path: "/wp-content/themes/hoan-supply/img/top/img_top_kv_02.json"
}
var icon02 = lottie.loadAnimation(
  Object.assign({}, defaultOption, addOption02)
);

// slide03
var addOption03= {
  container: document.querySelector(".kv-slider__img--03"),
  path: "/wp-content/themes/hoan-supply/img/top/img_top_kv_03.json"
}
var icon03 = lottie.loadAnimation(
  Object.assign({}, defaultOption, addOption03)
);


//exitLottieAnimation(element.dataset.number);

var funcIcon = {
  icon01: function(){
    icon01.play();
    icon03.stop();
  },
  icon02: function(){
    icon02.play();
    icon01.stop();
  },
  icon03: function(){
    icon03.play();
    icon02.stop();
  },
}
function exitLottieAnimation(number) {
  var func = funcIcon[number];
  func();
}
